<template>
  <div class="phone-wrapper">
    <el-form label-width="260px" size="small" :model="form" v-if="form.list.length>0" ref="form">
      <common-title title="用户端平台联系电话"></common-title>
      <div class="content">
        <el-form-item label="400电话：" :prop="'list.' + 0 + '.dictionaryValue'  " :rules="{ required: true, message: '400电话不能为空', trigger: 'blur'}">
          <el-input style="width: 300px" v-model="form.list[0].dictionaryValue"/>
        </el-form-item>
        <el-form-item label="客服电话：" :prop="'list.' + 1 + '.dictionaryValue'"  :rules="{ required: true, message: '客服电话不能为空', trigger: 'blur'}">
          <el-input style="width: 300px" v-model="form.list[1].dictionaryValue"/>
        </el-form-item>
      </div>


      <common-title title="供应端联系平台电话"></common-title>
      <div class="content">
        <el-form-item label="供应端删除服务单照片联系电话：" :prop="'list.' + 2 + '.dictionaryValue'"  :rules="{ required: true, message: '供应端删除服务单照片联系电话不能为空', trigger: 'blur'}">
          <el-input style="width: 300px" v-model="form.list[2].dictionaryValue"/>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="handleSave">确定</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import CommonTitle from "@/components/common-title/index.vue";

import {getDireactionListByKey,editDireactionListByKey} from '@/api/dictionary'

export default {
  name: "PhoneConfig",
  components: {
    CommonTitle
  },
  data () {
    return {
      form:{
        list: []
      }

    }
  },
  created () {
    this.getPhoneConfig()
  },
  computed: {
  },
  methods: {
    async getPhoneConfig () {
      try {
        const params = {
          data: {
            dictionaryKey: 'port_phone'
          }
        }
        const res = await getDireactionListByKey(params)
        if(res.success===false){
          this.$message.error(res.description)
          return
        }
        this.form.list = res;
      } catch (e) {
        console.log(e)
      }
    },

     handleSave(){
       this.$refs.form.validate(async (valid) => {
         if (valid) {
           const res = await editDireactionListByKey({data:this.form.list})
           if (res.success === false) {
             this.$message.error(res.description)
             return
           }
           this.$message.success('发布成功')
         }
         return false;
       });
    }
  }
}
</script>

<style scoped lang="less">
.phone-wrapper {
  .content {
    padding: 20px 100px 20px 0;
  }
}
</style>
